export const routes = [{
    path: "/",
    name: 'home',
    component: () => import('@/views/Home.vue'),
  },
  {
    path: "*",
    name: 'Error',
    component: () => import('@/components/Error.vue'),
    props: true
  },
];